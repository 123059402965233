<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageGenre v-else :genre />
  </div>
</template>

<script lang="ts" setup>
import type { Genre } from '#gql/default'

interface Props {
  genre: Genre
}

defineProps<Props>()

const { page } = useCms()

defineOptions({
  name: 'PageMoviesGenreSlugIndex',
})
</script>

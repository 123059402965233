<template>
  <slot :pending :genres :paginator-info />
</template>

<script lang="ts" setup>
import type { FetchGenresQueryVariables } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchGenresQueryVariables
}

const COMPONENT_NAME = 'GenresFetch'
const props = defineProps<Props>()

const { data, pending, execute, refresh } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchGenres(props.fetchParams),
  {
    immediate: false,
  }
)

await execute()
const genres = computed(() => data.value?.genres?.data ?? [])
const paginatorInfo = computed(() => data.value?.genres?.paginatorInfo)

watch(
  () => props.fetchParams,
  async () => {
    await refresh()
  },
  {
    deep: true,
  }
)

defineOptions({
  name: COMPONENT_NAME,
})
</script>

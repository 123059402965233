<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title', { genreName: genre.name })" />
        <CatalogMovies
          :layout="MOVIES_LAYOUT.VERTICAL"
          :genres="[genre.urlSlug!]"
          :filters="[
            URL_FILTER.SEARCH,
            URL_FILTER.CITY,
            URL_FILTER.PERIODS,
            URL_FILTER.CONTENT_RATINGS,
          ]"
          :fetch-fallback-results="true"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
import type { Genre } from '#gql/default'

interface Props {
  genre: Genre
}

const props = defineProps<Props>()

const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title', { genreName: props.genre.name }),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageGenre',
})
</script>

<i18n>
de:
  page:
    title: "Filme in \"{genreName}\""
    description: "Kinotickets für alle Städte &amp; alle Filme schnell und bequem online &amp; mobil kaufen – Große Auswahl an Kinos bei kinoheld mit E-ticket."
es:
  page:
    title: "Películas en \"{genreName}\""
    description: "Compre entradas de cine para todas las ciudades y todas las películas de forma rápida y cómoda en línea y móvil: gran selección de cines en entradas con E-ticket."
</i18n>

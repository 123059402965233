<template>
  <GenreFetch
    :genre-slug="route.params.genreSlug as string"
    :throw-error="true"
  >
    <template #default="{ genre, pending }">
      <NuxtPage v-if="!pending && genre" :genre="genre" />
    </template>
  </GenreFetch>
</template>

<script lang="ts" setup>
const route = useRoute()

defineI18nRoute({
  paths: {
    en: '/movies/genre/[genreSlug]',
    de: '/filme/genre/[genreSlug]',
    es: '/peliculas/generos/[genreSlug]',
  },
})

defineOptions({
  name: 'PageMoviesGenreSlug',
})
</script>

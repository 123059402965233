<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <CatalogMovies
          :layout="MOVIES_LAYOUT.VERTICAL"
          :filters
          :fetch-fallback-results="true"
          :items-per-page="20"
          :preserve-filters="true"
        />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { $gtm } = useNuxtApp()
const appConfig = useAppConfig()
const filters = computed(
  () => (appConfig.filtersForGenericMoviesPage as UrlFilter[]) ?? []
)

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageMovies',
})
</script>

<i18n>
de:
  page:
    title: "Filme"
    description: "Kinotickets für alle Städte &amp; alle Filme schnell und bequem online &amp; mobil kaufen – Große Auswahl an Kinos bei kinoheld mit E-ticket."
es:
  page:
    title: "Películas"
    description: "Compra tus entradas de cine para todas las ciudades y todas las películas de forma rápida y cómoda en línea y móvil: gran selección de cines en entradas con E-ticket."
</i18n>

<template>
  <PageContainer>
    <PageGrid>
      <PageGridColumn>
        <PageHeading :title="t('page.title')" />
        <GenreList fetch-key="genres" :fetch-params="{ first: 50, page }" />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'

const page = useRouteQuery('page', 1, { transform: Number })
const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title'),
  description: t('page.description'),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageGenres',
})
</script>

<i18n>
de:
  page:
    title: "Genres"
    description: "Wählen Sie Ihr Genre aus, um die für Sie passenden Filme zu sehen. Buchen Sie anschließend bequem online und erhalten Sie die Tickets sofort per E-Mail."
es:
  page:
    title: "Géneros"
    description: "Selecciona tu género para ver las películas que te interesan. Reserva cómodamente en línea y recibe tus entradas por correo electrónico."
</i18n>

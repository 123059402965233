<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageGenres v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/movies/genres',
    de: '/filme/genres',
    es: '/peliculas/generos',
  },
})

defineOptions({
  name: 'PageMoviesGenres',
})
</script>

<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageMovies v-else />
  </div>
</template>

<script lang="ts" setup>
const { page } = useCms()

defineI18nRoute({
  paths: {
    en: '/movies',
    de: '/filme',
    es: '/peliculas',
  },
})

defineOptions({
  name: 'PageMovies',
})
</script>
